import { Illustration } from '../types/Illustration';

export const illustrationsData: Illustration[] = [
  {
    id: '1',
    imageUrl: '/portfolio2/pf-1.PNG'
  },
  {
    id: '2',
    imageUrl: '/portfolio2/pf-2.PNG'
  },
  {
    id: '3',
    imageUrl: '/portfolio2/pf-3.PNG'
  },
  {
    id: '4',
    imageUrl: '/portfolio2/pf-4.PNG'
  },
  {
    id: '5',
    imageUrl: '/portfolio2/pf-5.PNG'
  },
  {
    id: '6',
    imageUrl: '/portfolio2/pf-6.PNG'
  },
  {
    id: '7',
    imageUrl: '/portfolio2/pf-7.PNG'
  },
  {
    id: '8',
    imageUrl: '/portfolio2/pf-8.PNG'
  },
  {
    id: '9',
    imageUrl: '/game_art/Softie_Attic.PNG'
  },
  {
    id: '10',
    imageUrl: '/game_art/Softie_Bedroom.PNG'
  },
  {
    id: '10',
    imageUrl: '/portfolio2/pf-9.PNG'
  }
];
